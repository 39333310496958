import type { IOliveError } from '~/types/errors.types'
import type { ILoyaltyProgram, ILoyaltyProgramRequestParams } from '~/types/loyaltyProgram.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'

export const useLoyaltyProgramsStore = defineStore('loyaltyPrograms', () => {
  const oliveBaseUrl = '/loyalty_programs'

  // #region state
  const loyaltyPrograms = ref<ILoyaltyProgram[]>([])
  const loyaltyProgramIdsCurrentPage = ref<string[]>([])
  const numberOfLoyaltyPrograms = ref<number | undefined>()
  const selectedLoyaltyProgramId = ref<string>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getLoyaltyProgramById = computed(() => {
    return (id: string) => loyaltyPrograms.value.find(l => l.id === id)
  })

  const getLoyaltyProgramsCurrentPage = computed(() => {
    return loyaltyPrograms.value.filter(l => loyaltyProgramIdsCurrentPage.value.includes(l.id))
  })

  const getActiveLoyaltyProgramsByClientId = computed(() => {
    return (clientId: string) => loyaltyPrograms.value.filter(l => l.active === true && l.clientId === clientId)
  })

  const getSelectedLoyaltyProgram = computed(() => {
    if (selectedLoyaltyProgramId.value)
      return getLoyaltyProgramById.value(selectedLoyaltyProgramId.value)
  })
  // #endregion

  // #region actions
  const loadLoyaltyPrograms = async (params?: ILoyaltyProgramRequestParams) => {
    const {
      response,
      error: loyaltyProgramError,
      run: loadLoyaltyPrograms,
    } = useOliveAPI<IPlatformResponse<ILoyaltyProgram>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Failed to get loyalty programs',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadLoyaltyPrograms()

    if (response.value?.items && !loyaltyProgramError.value.hasError) {
      loyaltyPrograms.value = useArrayUnique([...response.value.items, ...loyaltyPrograms.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        loyaltyProgramIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfLoyaltyPrograms.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = loyaltyProgramError.value }

    isLoading.value = false
  }

  const loadLoyaltyProgram = async (id: string) => {
    selectedLoyaltyProgramId.value = id

    if (getLoyaltyProgramById.value(id))
      return

    const {
      response,
      error: loyaltyProgramError,
      run: loadLoyaltyProgram,
    } = useOliveAPI<ILoyaltyProgram>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading loyalty program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadLoyaltyProgram()

    if (response.value && !loyaltyProgramError.value.hasError)
      loyaltyPrograms.value = useArrayUnique([response.value, ...loyaltyPrograms.value], (a, b) => a.id === b.id).value
    else error.value = loyaltyProgramError.value

    isLoading.value = false
  }

  const addLoyaltyProgram = async (lp: ILoyaltyProgram) => {
    const {
      response,
      error: loyaltyProgramError,
      run: addLoyaltyProgram,
    } = useOliveAPI<ILoyaltyProgram>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: lp,
      successMessage: 'Loyalty Program created',
      errorMessage: 'Failed to create Loyalty Program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addLoyaltyProgram()

    if (response.value && !loyaltyProgramError.value.hasError) {
      loyaltyPrograms.value = useArrayUnique([response.value, ...loyaltyPrograms.value], (a, b) => a.id === b.id).value
      selectedLoyaltyProgramId.value = response.value.id
    }
    else { error.value = loyaltyProgramError.value }

    isLoading.value = false
  }

  const updateLoyaltyProgram = async (id: string, params: ILoyaltyProgramRequestParams) => {
    selectedLoyaltyProgramId.value = id

    const {
      response,
      error: loyaltyProgramError,
      run: updateLoyaltyProgram,
    } = useOliveAPI<ILoyaltyProgram>({
      method: HTTP_METHOD.PUT,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/${id}`),
      data: params,
      successMessage: 'Loyalty Program updated',
      errorMessage: 'Failed to save changes to the Loyalty Program',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateLoyaltyProgram()

    if (response.value && !loyaltyProgramError.value.hasError)
      loyaltyPrograms.value = useArrayUnique([response.value, ...loyaltyPrograms.value], (a, b) => a.id === b.id).value
    else error.value = loyaltyProgramError.value

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    loyaltyProgramIdsCurrentPage.value = []
  }

  const clearSelectedLoyaltyProgram = () => {
    selectedLoyaltyProgramId.value = undefined
  }
  // #endregion

  return {
    loyaltyPrograms,
    numberOfLoyaltyPrograms,
    isLoading,
    getLoyaltyProgramById,
    getActiveLoyaltyProgramsByClientId,
    getLoyaltyProgramsCurrentPage,
    getSelectedLoyaltyProgram,
    clearSelectedLoyaltyProgram,
    error,
    loadLoyaltyProgram,
    loadLoyaltyPrograms,
    addLoyaltyProgram,
    updateLoyaltyProgram,
    clearCurrentPage,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLoyaltyProgramsStore as any, import.meta.hot))
