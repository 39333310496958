import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { IRoundUpRule, IRoundUpRuleRequestParams } from '~/types/roundUpRule.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'

export const useRoundUpRulesStore = defineStore('roundUpRules', () => {
  const oliveBaseUrl = '/round_up_rules'

  // #region state
  const roundUpRules = ref<IRoundUpRule[]>([])
  const roundUpRuleIdsCurrentPage = ref<string[]>([])
  const numberOfRoundUpRules = ref<number>(0)
  const numberOfPages = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IRoundUpRuleRequestParams>({})

  const selectedRoundUpRuleId = ref<string>()

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getRoundUpRuleById = computed(() => {
    return (id: string) => roundUpRules.value.find(r => r.id === id)
  })

  const getRoundUpRulesCurrentPage = computed (() => {
    return roundUpRules.value.filter(r => roundUpRuleIdsCurrentPage.value.includes(r.id))
  })

  const getSelectedRoundUpRule = computed(() => {
    if (selectedRoundUpRuleId.value)
      return getRoundUpRuleById.value(selectedRoundUpRuleId.value)
  })
  // #endregion

  // #region actions
  const loadRoundUpRules = async (params?: IRoundUpRuleRequestParams) => {
    const {
      response,
      error: roundUpRuleError,
      run: loadRoundUpRules,
    } = useOliveAPI<IPlatformResponse<IRoundUpRule>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading round up rules',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadRoundUpRules()

    if (response.value?.items && !roundUpRuleError.value.hasError) {
      roundUpRules.value = useArrayUnique([...response.value.items, ...roundUpRules.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        numberOfPages.value = response.value.totalNumberOfPages
        roundUpRuleIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfRoundUpRules.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = roundUpRuleError.value }

    isLoading.value = false
  }

  const loadRoundUpRule = async (id: string) => {
    selectedRoundUpRuleId.value = id

    if (getRoundUpRuleById.value(id))
      return

    const {
      response,
      error: roundUpRuleError,
      run: loadRoundUpRule,
    } = useOliveAPI<IRoundUpRule>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading round up rule',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadRoundUpRule()

    if (response.value && !roundUpRuleError.value.hasError)
      roundUpRules.value = useArrayUnique([response.value, ...roundUpRules.value], (a, b) => a.id === b.id).value
    else
      error.value = roundUpRuleError.value

    isLoading.value = false
  }

  const addRoundUpRule = async (roundUpRule: IRoundUpRule) => {
    const {
      response,
      error: roundUpRuleError,
      run: addRoundUpRule,
    } = useOliveAPI<IRoundUpRule>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: roundUpRule,
      successMessage: 'Round Up Rule created successfully',
      errorMessage: 'Failed to create Round Up Rule',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addRoundUpRule()

    if (response.value && !roundUpRuleError.value.hasError) {
      roundUpRules.value = useArrayUnique([response.value, ...roundUpRules.value], (a, b) => a.id === b.id).value
      selectedRoundUpRuleId.value = response.value.id
    }
    else {
      error.value = roundUpRuleError.value
    }

    isLoading.value = false
  }

  const updateRoundUpRule = async (roundUpRule: IRoundUpRule) => {
    selectedRoundUpRuleId.value = roundUpRule.id
    const {
      response,
      error: roundUpRuleError,
      run: updateStore,
    } = useOliveAPI<IRoundUpRule>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${roundUpRule.id}`,
      data: roundUpRule,
      successMessage: 'Round Up rule changes have been saved',
      errorMessage: 'Failed to update Round Up Rule',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateStore()

    if (response.value && !roundUpRuleError.value.hasError)
      roundUpRules.value = useArrayUnique([response.value, ...roundUpRules.value], (a, b) => a.id === b.id).value
    else
      error.value = roundUpRuleError.value

    isLoading.value = false
  }

  const deleteRoundUpRule = async (id: string) => {
    const {
      error: roundUpRuleError,
      run: deleteRoundUpRule,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${id}`,
      successMessage: 'Round Up Rule successfully removed',
      errorMessage: 'Failed to remove Round Up Rule',
    })
    isLoading.value = true
    error.value = getOliveError()

    await deleteRoundUpRule()

    if (!roundUpRuleError.value.hasError) {
      const roundUpRule = getRoundUpRuleById.value(id)
      if (roundUpRule)
        roundUpRules.value = roundUpRules.value.filter(r => r.id !== id)
    }
    else { error.value = roundUpRuleError.value }

    isLoading.value = false
  }

  const clearCurrentPage = () => {
    roundUpRuleIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedRoundUpRule = () => {
    selectedRoundUpRuleId.value = undefined
  }
  // #endregion

  return {
    roundUpRules,
    getRoundUpRuleById,
    getRoundUpRulesCurrentPage,
    getSelectedRoundUpRule,
    clearSelectedRoundUpRule,
    numberOfRoundUpRules,
    numberOfPages,
    error,
    isLoading,
    filter,
    loadRoundUpRules,
    loadRoundUpRule,
    addRoundUpRule,
    updateRoundUpRule,
    deleteRoundUpRule,
    clearCurrentPage,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRoundUpRulesStore as any, import.meta.hot))
