import type { RouteParams } from 'vue-router'
import type { LOGO_SIZE } from '~/types/logo.types'
import countries from 'i18n-iso-countries'

import isoCountriesEn from 'i18n-iso-countries/langs/en.json'
import { redirectToPageNotFound } from '~/router'
import { COUNTRY_CODE } from '~/types/client.types'
import { POSTALCODE_OR_ZIPCODE, STATE_OR_PROVINCE, SUPPORTED_LOCALE } from '~/types/common.types'

function guidValidation(guidString: string) {
  const rule = guidRule().at(0)
  return rule ? rule(guidString ?? '') : true
}

function getJSONObject(jsonString: string) {
  try {
    const res = JSON.parse(jsonString)
    return res
  }
  catch (e) {
    return 'Error parsing JSON'
  }
}

function isValidJSONObject(jsonString: string) {
  try {
    if (!getJSONObject(jsonString).includes('Error parsing JSON'))
      return true
  }
  catch (e) {
    return false
  }
}

async function getClientsDefaultLogoSize(clientId?: string, corporateId?: string): Promise<LOGO_SIZE | undefined> {
  const clientsStore = useClientsStore()
  if (clientId) {
    return clientsStore.getClientById(clientId)?.logoSize
  }
  else if (corporateId) {
    const corporate = useCorporatesStore().getCorporateById(corporateId)
    if (corporate) {
      const client = clientsStore.getClientById(corporate.clientId)
      return client?.logoSize
    }
  }
  return undefined
}

function toSplitCamelCase(e: string) {
  if (typeof e !== 'string')
    return

  return e
    .split('_')
    .map((word) => {
      if (word.toLowerCase() === 'id')
        return 'ID'
      if (word.toLowerCase() === 'sso')
        return 'SSO'
      if (word.toLowerCase() === 'oidc')
        return 'OIDC'

      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

// Helper method to map Olive Enum data contract to a well formatted string to be used for presentation
function oliveEnumToReadableStringMapper(input: string | string[]) {
  if (Array.isArray(input)) {
    return input.map((input) => {
      return toSplitCamelCase(input)
    })
  }
  else {
    return toSplitCamelCase(input)
  }
}

// Helper method to map a string to an Olive Enum representation
function oliveEnumMapper(input: string | undefined) {
  if (input)
    return input.toLowerCase().replace(/\s+/g, '_')
}

function findClosestSliderIndex(target: number | undefined, sliderSteps: number[]): number | undefined {
  if (target === undefined)
    return

  let closestIndex = 0
  let closestDifference = Math.abs(target - sliderSteps[0])

  for (let i = 1; i < sliderSteps.length; i++) {
    const currentDifference = Math.abs(target - sliderSteps[i])

    if (currentDifference < closestDifference) {
      closestIndex = i
      closestDifference = currentDifference
    }
  }

  return closestIndex
}

function getClientLocale(clientId: string) {
  const countryCode = useClientsStore().getClientById(clientId)?.countryCode
  if (countryCode) {
    switch (countryCode) {
      case COUNTRY_CODE.USA:
        return SUPPORTED_LOCALE.EN_US
      case COUNTRY_CODE.CANADA:
      default:
        return SUPPORTED_LOCALE.EN_CA
    }
  }
  return SUPPORTED_LOCALE.EN_CA
}

function getLocaleName(locale: string) {
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' })
  return `${languageNames.of(locale) as string} (${locale})`
}

function getCountryName(twoLetterCountryCode: string): string {
  countries.registerLocale(isoCountriesEn)
  const countryNames = countries.getNames('en')
  const countryName = countryNames[twoLetterCountryCode]
  return countryName || twoLetterCountryCode
}

async function loadStoresOrBrandsByIds(brandIds?: (string | undefined)[], storeIds?: (string | undefined)[]) {
  const filteredBrandIds: string[] = (brandIds?.filter((id): id is string => id !== undefined)) || []
  const filteredStoreIds: string[] = (storeIds?.filter((id): id is string => id !== undefined)) || []

  await Promise.all([useBrandsStore().loadBrandsByIds(filteredBrandIds), useStoresStore().loadStoresByIds(filteredStoreIds)])
}

function setFocusOnElementById(elementId: string) {
  const input = document.getElementById(elementId) as HTMLInputElement
  if (input) {
    setTimeout(() => {
      input.focus()
    }, 100)
  }
}

function isEmptyString(value: string | undefined | null) {
  return (value == null || value === undefined || (typeof value === 'string' && value.trim().length === 0))
}

// returns a value of a primitive data type or the first item of an array/object
// returns undefined otherwise
function getFirstValue(obj: any): any {
  if (obj === undefined || obj === null || (typeof obj !== 'object' && !Array.isArray(obj)))
    return obj

  const keys = Object.keys(obj)
  if (keys.length > 0)
    return obj[keys[0]]

  return undefined
}

function oliveFullTextSearchConverter(value: string | undefined) {
  if (!isEmptyString(value))
    return `"${value!.replace(/"([^"]+)"/g, (_, match) => `${match}`).replace(/"/g, '').trim()}${value!.includes('*') ? '' : '*'}"`
}

function validateGuid(value: string | undefined) {
  if (!isEmptyString(value) && !guidRules.test(value as string))
    redirectToPageNotFound()
  else if (value === undefined)
    return ref(undefined)
  else
    return ref(value.toLowerCase())
}

function routeParamsToLowerCase(obj: RouteParams, validateGUID: boolean): RouteParams {
  const result: RouteParams = {}

  for (const key in obj) {
    const value = obj[key]
    if (typeof value === 'string') {
      result[key] = value.toLowerCase()
      if (validateGUID && !guidRules.test(result[key] as string))
        redirectToPageNotFound()
    }
    else if (Array.isArray(value)) {
      result[key] = value.map((item) => {
        if (typeof item === 'string') {
          if (validateGUID && !guidRules.test(result[key] as string))
            redirectToPageNotFound()
          return item.toLowerCase()
        }
        else {
          return item
        }
      })
    }
    else { result[key] = value }
  }

  return result
}

function getNormalizedRouteParams(validateGUID = true): RouteParams {
  const routeParams = routeParamsToLowerCase(useRoute().params, validateGUID)
  return routeParams
}

function groupBy(arr: any[], property: string) {
  return arr.reduce((memo: any, x: any) => {
    if (!memo[x[property]])
      memo[x[property]] = []
    memo[x[property]].push(x)
    return memo
  }, {})
}

function getRandomIntInclusive(min: number, max: number) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
}

// check equality of two objects based on an object's property
function areArraysOfObjectsEqual(arr1: any[], arr2: any[], property: string): boolean {
  if (arr1 && arr2) {
    if (arr1.length !== arr2.length)
      return false

    const sortedArr1 = arr1.slice().sort((a, b) => a[property] < b[property] ? -1 : 1)
    const sortedArr2 = arr2.slice().sort((a, b) => a[property] < b[property] ? -1 : 1)

    return sortedArr1.every(obj1 => sortedArr2.some(obj2 => obj1[property] === obj2[property]))
  }
  return false
}

function mergeArrays<T>(...arrays: T[][]): T[] {
  return arrays.reduce((mergedArray, array) => mergedArray.concat(array), [])
}

function isStateOrProvince(countryCode?: string) {
  if (!isEmptyString(countryCode)) {
    switch (countryCode) {
      case 'CA':
        return STATE_OR_PROVINCE.PROVINCE
      case 'US':
        return STATE_OR_PROVINCE.STATE
    }
  }
}

function isPostalCodeOrZipCode(countryCode: string) {
  switch (countryCode) {
    case 'CA':
      return POSTALCODE_OR_ZIPCODE.POSTAL_CODE
    case 'US':
      return POSTALCODE_OR_ZIPCODE.ZIP_CODE
  }
}

function serializeIterable<T>(iterable: Iterable<T>): { [key: string]: T } {
  const serializedData: { [key: string]: T } = {}
  for (const item of iterable) {
    const [key, value] = item as [string, T]
    serializedData[key] = value
  }
  return serializedData
}

function getDateTimeUTCFilename() {
  return new Date().toISOString().replaceAll(':', '.')
}

function isSuperset(a: string[], b: string[]): boolean {
  const setA = new Set(a)
  const setB = new Set(b)

  for (const item of setB) {
    if (!setA.has(item)) {
      return false
    }
  }
  return true
}

export {
  areArraysOfObjectsEqual,
  findClosestSliderIndex,
  getClientLocale,
  getClientsDefaultLogoSize,
  getCountryName,
  getDateTimeUTCFilename,
  getFirstValue,
  getJSONObject,
  getLocaleName,
  getNormalizedRouteParams,
  getRandomIntInclusive,
  groupBy,
  guidValidation,
  isEmptyString,
  isPostalCodeOrZipCode,
  isStateOrProvince,
  isSuperset,
  isValidJSONObject,
  loadStoresOrBrandsByIds,
  mergeArrays,
  oliveEnumMapper,
  oliveEnumToReadableStringMapper,
  oliveFullTextSearchConverter,
  serializeIterable,
  setFocusOnElementById,
  toSplitCamelCase,
  validateGuid,
}
