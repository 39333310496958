import type { IOliveError } from '~/types/errors.types'
import type { IOffer } from '~/types/offer.types'
import type { IPlatformResponse } from '~/types/platform.types'
import type { IStore, IStoreExternalIdentifier, IStoreLinkIdentifierRequestParams, IStoreRequestParams } from '~/types/store.types'

import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { OLIVE_ERROR_CODE } from '~/types/errors.types'

const BATCH_SIZE = 25

export const useStoresStore = defineStore('stores', () => {
  const oliveBaseUrl = '/stores'

  // #region state
  const stores = ref<IStore[]>([])
  const selectedStoreId = ref<string>()

  const offerStores = ref<IStore[]>([])
  const filteredStores = ref<IStore[]>([])
  const storeIdsCurrentPage = ref<string[]>([])
  const storeIdsExport = ref<string[]>([])
  const numberOfStores = ref<number>(0)
  const numberOfPages = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IStoreRequestParams>({})

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getStoreById = computed(() => {
    return (id: string) => stores.value.find(s => s.id === id)
  })

  const getStoresByIds = computed(() => {
    return (ids: string[]) => stores.value.filter(s => ids.includes(s.id))
  })

  const getStoresCurrentPage = computed (() => {
    return stores.value.filter(s => storeIdsCurrentPage.value.includes(s.id))
  })

  const getStoresForExport = computed (() => {
    return stores.value.filter(s => storeIdsExport.value.includes(s.id))
  })

  const getStoresExternaIdentifiers = computed (() => {
    return (storeId: string) => getStoreById.value(storeId)?.externalIdentifiers
  })

  const getSelectedStore = computed(() => {
    if (selectedStoreId.value)
      return getStoreById.value(selectedStoreId.value)
  })
  // #endregion

  // #region actions
  const loadStores = async (params?: IStoreRequestParams, exportStores = false) => {
    const {
      response,
      error: storeError,
      run: loadStores,
    } = useOliveAPI<IPlatformResponse<IStore>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading stores',
    })
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadStores()

    if (response.value?.items && !storeError.value.hasError) {
      stores.value = useArrayUnique([...response.value.items, ...stores.value], (a, b) => a.id === b.id).value
      numberOfPages.value = response.value.totalNumberOfPages

      if (exportStores) {
        storeIdsExport.value = [...response.value.items.map(m => m.id)]
      }
      else {
        if (latestRequestTime.value === currentRequestDateTime) {
          storeIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
          numberOfStores.value = response.value.totalNumberOfRecords
        }
      }
    }
    else { error.value = storeError.value }

    isLoading.value = false
  }

  const loadAllStores = async (params?: IStoreRequestParams) => {
    filteredStores.value = []
    let pageNumber = 1
    const pageSize = 1000

    do {
      await loadStores({ ...params, pageNumber, pageSize }, true)
      filteredStores.value.push(...getStoresForExport.value)
      pageNumber++
    } while (pageNumber <= Number(numberOfPages.value))
  }

  const loadStore = async (id: string) => {
    selectedStoreId.value = id
    if (getStoreById.value(id))
      return

    const {
      response,
      error: storeError,
      run: loadStore,
    } = useOliveAPI<IStore>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading store',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadStore()

    if (response.value && !storeError.value.hasError)
      stores.value = useArrayUnique([response.value, ...stores.value], (a, b) => a.id === b.id).value
    else
      error.value = storeError.value

    isLoading.value = false
  }

  const loadStoresByIds = async (ids: string[]) => {
    const storeIdsFiltered = [...new Set(ids.filter(s => !getStoreById.value(s)).map(t => t as string).filter(s => !(s === null || s === undefined)))]

    if (storeIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadStores({ pageSize: storeIdsFiltered.slice(0, BATCH_SIZE).length, storeId: storeIdsFiltered.slice(0, BATCH_SIZE) }))
      storeIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (storeIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadOfferStores = async (offerId: string) => {
    const {
      response,
      error: storeError,
      run: loadOfferStores,
    } = useOliveAPI<IStore[]>({
      method: HTTP_METHOD.GET,
      url: `offers/${offerId}/stores`,
      errorMessage: 'Error loading stores',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadOfferStores()

    if (response.value && !storeError.value.hasError) {
      offerStores.value = response.value
      stores.value = useArrayUnique([...response.value, ...stores.value], (a, b) => a.id === b.id).value
    }
    else { error.value = storeError.value }

    isLoading.value = false
  }

  const addStore = async (store: IStore) => {
    const {
      response,
      error: storeError,
      run: addStore,
    } = useOliveAPI<IStore>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: store,
      successMessage: 'Store created successfully',
      errorMessage: 'Failed to create a store',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addStore()

    if (response.value && !storeError.value.hasError) {
      stores.value = useArrayUnique([response.value, ...stores.value], (a, b) => a.id === b.id).value
      selectedStoreId.value = response.value.id
    }
    else { error.value = storeError.value }

    isLoading.value = false
  }

  const updateStore = async (store: IStore) => {
    selectedStoreId.value = store.id

    const {
      response,
      error: storeError,
      run: updateStore,
    } = useOliveAPI<IStore>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${store.id}`,
      data: store,
      successMessage: 'Store changes have been saved',
      errorMessage: 'Failed to update store',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateStore()

    if (response.value && !storeError.value.hasError)
      stores.value = useArrayUnique([response.value, ...stores.value], (a, b) => a.id === b.id).value
    else
      error.value = storeError.value

    isLoading.value = false
  }

  const addStoresExternalIdentifier = async (storeId: string, identifier: IStoreExternalIdentifier) => {
    const {
      response,
      error: storeError,
      run: addStoresExternalIdentifier,
    } = useOliveAPI<IStoreExternalIdentifier[]>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${storeId}/external_identifiers`,
      data: { Identifiers: [identifier] },
      successMessage: 'External Identifier added to store',
      errorMessage: 'Failed to add External Identifier to store',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addStoresExternalIdentifier()

    if (response.value && !storeError.value.hasError) {
      const store = getStoreById.value(storeId)
      if (store)
        store.externalIdentifiers = response.value
    }
    else { error.value = storeError.value }

    isLoading.value = false
  }

  const deleteStoresExternalIdentifier = async (storeId: string, externalIdentifierId: string) => {
    const {
      error: storeError,
      run: deleteStoresExternalIdentifier,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${storeId}/external_identifiers/${externalIdentifierId}`,
      successMessage: 'External Identifier removed from store',
      errorMessage: 'Failed to remove External Identifier',
    })
    isLoading.value = true
    error.value = getOliveError()

    await deleteStoresExternalIdentifier()

    if (!storeError.value.hasError) {
      const store = getStoreById.value(storeId)
      if (store)
        store.externalIdentifiers = store.externalIdentifiers.filter(f => f.id !== externalIdentifierId)
    }
    else { error.value = storeError.value }

    isLoading.value = false
  }

  const mergeStores = async (srcStoreId: string, destStoreId: string) => {
    const {
      error: storeError,
      run: mergeStores,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${destStoreId}/merge/${srcStoreId}`,
      successMessage: 'Stores have been merged successfully',
      errorMessage: 'Failed to merge Stores',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadStore(srcStoreId)
    const srcStore = getStoreById.value(srcStoreId) as IStore

    await mergeStores()

    if (!storeError.value.hasError) {
      await loadStore(destStoreId)
      const destStore = getStoreById.value(destStoreId) as IStore

      destStore.externalIdentifiers = [
        ...destStore.externalIdentifiers,
        ...srcStore.externalIdentifiers.filter((srcIdentifier) => {
          return !destStore.externalIdentifiers.some(destIdentifier => destIdentifier.id === srcIdentifier.id)
        }),
      ]

      useOffersStore().offers = useOffersStore().offers.map((offer: IOffer) => {
        const updatedStores = offer.stores.map(store => (store === srcStoreId ? destStoreId : store))
        return { ...offer, stores: updatedStores }
      })

      useOffersStore().offers = useOffersStore().offers.map((offer: IOffer) => {
        const updatedStoreDetails = offer.storeDetails.map(storeDetail =>
          storeDetail.id === srcStoreId ? destStore : storeDetail,
        )
        return { ...offer, storeDetails: updatedStoreDetails }
      })

      useCardTransactionsStore().transactions = useCardTransactionsStore().transactions.filter(t => t.storeId === srcStoreId).map(t => ({ ...t, storeId: destStoreId }))

      stores.value = stores.value.filter(s => s.id !== srcStoreId)
    }
    else { error.value = storeError.value }
    isLoading.value = false
  }

  const addStoreExternalIdentifiersFromAuthorization = async (storeId: string, link: IStoreLinkIdentifierRequestParams) => {
    const {
      response,
      error: storeError,
      run: linkStoresExternalIdentifier,
    } = useOliveAPI<IStoreExternalIdentifier[]>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${storeId}/external_identifiers/add_from_authorization`,
      data: link,
      successMessage: 'Linking External Identifier added to store',
      errorMessage: 'Failed to add External Identifier to store',
    })
    isLoading.value = true
    error.value = getOliveError()

    await linkStoresExternalIdentifier()

    if (response.value && !storeError.value.hasError) {
      const store = getStoreById.value(storeId)
      if (store) {
        const responseIds = response.value.map(m => m.id)
        const externalIds = store.externalIdentifiers.map(ei => ei.id)

        responseIds.forEach((id) => {
          const index = externalIds.indexOf(id)
          if (index !== -1) {
            externalIds.splice(index, 1)
          }
        })

        if (responseIds.length === 0) {
          error.value = getOliveError(true, OLIVE_ERROR_CODE.AddExternalIdentifiersFromAuth_NoNewIdentifiers)
        }
        else {
          store.externalIdentifiers = response.value
        }
      }
    }
    else {
      error.value = storeError.value
    }

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    storeIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedStore = () => {
    selectedStoreId.value = undefined
  }

  // #endregion

  return {
    stores,
    offerStores,
    filteredStores,
    numberOfStores,
    isLoading,
    filter,
    getStoreById,
    getStoresByIds,
    getStoresCurrentPage,
    getStoresExternaIdentifiers,
    getSelectedStore,
    clearSelectedStore,
    error,
    loadStores,
    loadAllStores,
    loadOfferStores,
    loadStoresByIds,
    loadStore,
    updateStore,
    addStore,
    addStoresExternalIdentifier,
    deleteStoresExternalIdentifier,
    addStoreExternalIdentifiersFromAuthorization,
    clearCurrentPage,
    mergeStores,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useStoresStore as any, import.meta.hot))
