// #region enums
export enum PERMISSION {
  CAN_CREATE_ACCOUNT_TRANSACTIONS = 'create:account-transactions',
  CAN_ACCESS_ALL_CLIENTS = 'all:clients',
  CAN_CREATE_CLIENTS = 'create:clients',
  CAN_CREATE_CORPORATES = 'create:corporates',
  CAN_CREATE_PAYMENT_METHODS = 'create:payment-methods',
  CAN_CREATE_SCHEDULED_JOBS = 'create:scheduled-jobs',
  CAN_CREATE_STORE_EXT_IDS = 'create:store-ext-ids',
  CAN_CREATE_SYSTEM = 'create:system',
  CAN_CREATE_TRANSACTIONS = 'create:transactions',
  CAN_CREATE_USERS = 'create:users',
  CAN_DELETE_CLIENTS = 'delete:clients',
  CAN_DELETE_CORPORATES = 'delete:corporates',
  CAN_DELETE_SCHEDULED_JOBS = 'delete:scheduled-jobs',
  CAN_DELETE_STORE_EXT_IDS = 'delete:store-ext-ids',
  CAN_DELETE_SYSTEM = 'delete:system',
  CAN_DELETE_USERS = 'delete:users',
  CAN_UPDATE_BRANDS = 'update:brands',
  CAN_UPDATE_CLIENTS = 'update:clients',
  CAN_UPDATE_CORPORATES = 'update:corporates',
  CAN_UPDATE_OFFER_STATES = 'update:offer-states',
  CAN_UPDATE_PAYMENT_METHODS = 'update:payment-methods',
  CAN_UPDATE_SCHEDULED_JOBS = 'update:scheduled-jobs',
  CAN_UPDATE_SYSTEM = 'update:system',
  CAN_UPDATE_TRANSACTION_REWARDS = 'update:transaction-rewards',
  CAN_UPDATE_USERS = 'update:users',
  CAN_VIEW_ACCOUNTS = 'view:accounts',
  CAN_VIEW_BRANDS = 'view:brands',
  CAN_VIEW_CLIENTS = 'view:clients',
  CAN_VIEW_CORPORATES = 'view:corporates',
  CAN_VIEW_DOCUMENTATION = 'view:documentation',
  CAN_VIEW_MC_MERCHANT_DETAILS = 'view:mc-merchant-details',
  CAN_VIEW_PAYMENT_METHODS = 'view:payment-methods',
  CAN_VIEW_PAYMENT_NETWORK_CARDS = 'view:payment-network-cards',
  CAN_VIEW_PAYMENT_NETWORK_TRANSACTIONS = 'view:payment-network-transactions',
  CAN_VIEW_SCHEDULED_JOBS = 'view:scheduled-jobs',
  CAN_VIEW_SYSTEM = 'view:system',
  CAN_VIEW_TRANSACTIONS = 'view:transactions',
  CAN_VIEW_USERS = 'view:users',
  CAN_VIEW_VISA_MERCHANT_DETAILS = 'view:visa-merchant-details',
}
// #endregion
