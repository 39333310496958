import type { IBrand, IBrandCategoryRequestParams, IBrandExternalIdentifier, IBrandExternalIdentifierParms, IBrandRequestParams } from '~/types/brand.types'
import type { OliveRequestParams } from '~/types/common.types'
import type { IOliveError } from '~/types/errors.types'
import type { IPlatformResponse } from '~/types/platform.types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { HTTP_METHOD } from '~/types/common.types'
import { LOGO_SIZE_SHORT } from '~/types/logo.types'

const BATCH_SIZE = 25

export const useBrandsStore = defineStore('brands', () => {
  const oliveBaseUrl = '/brands'
  const imageProxyUrl = '/image_proxy'

  // #region state
  const brands = ref<IBrand[]>([])
  const selectedBrandId = ref<string>()
  const brandIdsCurrentPage = ref<string[]>([])
  const brandsParentCategory = ref<string[]>([])
  const brandsFirstCategory = ref<string[]>([])
  const brandsSecondCategory = ref<string[]>([])
  const brandsExternalIdentifiers = ref<IBrandExternalIdentifier[]>([])
  const numberOfBrands = ref<number | undefined>()
  const error = ref<IOliveError>(getOliveError())
  const isLoading = ref(false)
  const filter = ref<IBrandRequestParams>({})
  const downloadedImage = ref<Blob>()

  const latestRequestTime = ref()
  // #endregion

  // #region getters
  const getBrandById = computed(() => {
    return (id: string) => brands.value.find(b => b.id === id)
  })

  const getBrandByIds = computed(() => {
    return (ids: string[]) => brands.value.filter(b => ids.includes(b.id))
  })

  const getBrandsCurrentPage = computed (() => {
    return brands.value.filter(b => brandIdsCurrentPage.value.includes(b.id))
  })

  const getBrandExternaIdentifiers = computed (() => {
    return (brandId: string) => brandsExternalIdentifiers.value.filter(b => b.brandId === brandId)
  })

  const getSelectedBrand = computed(() => {
    if (selectedBrandId.value)
      return getBrandById.value(selectedBrandId.value)
  })
  // #endregion

  // #region actions
  const loadBrands = async (params?: IBrandRequestParams, signal?: AbortSignal) => {
    const {
      response,
      error: brandError,
      run: loadBrands,
    } = useOliveAPI<IPlatformResponse<IBrand>>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(oliveBaseUrl, params),
      errorMessage: 'Error loading brands',
    }, signal)
    isLoading.value = true
    error.value = getOliveError()

    const currentRequestDateTime = (new Date()).getTime()
    latestRequestTime.value = currentRequestDateTime

    await loadBrands()

    if (response.value?.items && !brandError.value.hasError) {
      brands.value = useArrayUnique([...response.value.items, ...brands.value], (a, b) => a.id === b.id).value

      if (latestRequestTime.value === currentRequestDateTime) {
        brandIdsCurrentPage.value = [...response.value.items.map(m => m.id)]
        numberOfBrands.value = response.value.totalNumberOfRecords
      }
    }
    else { error.value = brandError.value }

    isLoading.value = false
  }

  const loadBrand = async (id: string) => {
    selectedBrandId.value = id

    if (getBrandById.value(id))
      return

    const {
      response,
      error: brandError,
      run: loadBrand,
    } = useOliveAPI<IBrand>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/${id}`,
      errorMessage: 'Error loading brand',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadBrand()

    if (response.value && !brandError.value.hasError)
      brands.value = useArrayUnique([response.value, ...brands.value], (a, b) => a.id === b.id).value
    else error.value = brandError.value

    isLoading.value = false
  }

  // #region Categories
  const loadBrandsParentCategory = async () => {
    const {
      response,
      error: brandError,
      run: loadBrandsParentCategory,
    } = useOliveAPI<string[]>({
      method: HTTP_METHOD.GET,
      url: `${oliveBaseUrl}/parentcategory`,
      errorMessage: 'Error loading brands',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadBrandsParentCategory()

    if (response.value && !brandError.value.hasError)
      brandsParentCategory.value = response.value
    else error.value = brandError.value

    isLoading.value = false
  }

  const loadBrandsFirstCategory = async (params?: IBrandCategoryRequestParams) => {
    const {
      response,
      error: brandError,
      run: loadBrandsFirstCategory,
    } = useOliveAPI<string[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/firstcategory`, params),
      errorMessage: 'Error loading brands',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadBrandsFirstCategory()

    if (response.value && !brandError.value.hasError)
      brandsFirstCategory.value = response.value
    else error.value = brandError.value

    isLoading.value = false
  }

  const loadBrandsSecondCategory = async (params?: IBrandCategoryRequestParams) => {
    const {
      response,
      error: brandError,
      run: loadBrandsSecondCategory,
    } = useOliveAPI<string[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/secondcategory`, params),
      errorMessage: 'Error loading brands',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadBrandsSecondCategory()

    if (response.value && !brandError.value.hasError)
      brandsSecondCategory.value = response.value
    else error.value = brandError.value

    isLoading.value = false
  }
  // #endregion

  const loadBrandsByIds = async (ids: string[]) => {
    const brandIdsFiltered = [...new Set(ids.filter(b => !getBrandById.value(b)).map(t => t as string).filter(s => s !== null && s !== undefined))]

    if (brandIdsFiltered.length === 0)
      return

    const batches = []
    do {
      batches.push(loadBrands({ pageSize: brandIdsFiltered.slice(0, BATCH_SIZE).length, brandId: brandIdsFiltered.slice(0, BATCH_SIZE) }))
      brandIdsFiltered.splice(0, BATCH_SIZE)
    }
    while (brandIdsFiltered.length > 0)
    await Promise.all(batches)
  }

  const loadBrandExternalIdentifiers = async (brandId: string, params?: IBrandExternalIdentifierParms) => {
    const {
      response,
      error: brandError,
      run: loadBrandExternalIdentifiers,
    } = useOliveAPI<IBrandExternalIdentifier[]>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(`${oliveBaseUrl}/${brandId}/external_identifiers`, params),
      errorMessage: 'Error loading brand external identifiers',
    })
    isLoading.value = true
    error.value = getOliveError()

    await loadBrandExternalIdentifiers()

    if (response.value && !brandError.value.hasError)
      brandsExternalIdentifiers.value = [...response.value]
    else error.value = brandError.value

    isLoading.value = false
  }

  const addBrand = async (brand: IBrand) => {
    const {
      response,
      error: brandError,
      run: addBrand,
    } = useOliveAPI<IBrand>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}`,
      data: brand,
      errorMessage: 'Failed to create a brand',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addBrand()

    if (response.value && !brandError.value.hasError) {
      brands.value = useArrayUnique([response.value, ...brands.value], (a, b) => a.id === b.id).value
      selectedBrandId.value = response.value.id
    }
    else { error.value = brandError.value }

    isLoading.value = false
  }

  const updateBrand = async (brand: IBrand) => {
    selectedBrandId.value = brand.id

    const {
      response,
      error: brandError,
      run: updateBrand,
    } = useOliveAPI<IBrand>({
      method: HTTP_METHOD.PUT,
      url: `${oliveBaseUrl}/${brand.id}`,
      data: brand,
      errorMessage: 'Failed to update brand',
    })
    isLoading.value = true
    error.value = getOliveError()

    await updateBrand()

    if (response.value && !brandError.value.hasError)
      brands.value = useArrayUnique([response.value, ...brands.value], (a, b) => a.id === b.id).value
    else error.value = brandError.value

    isLoading.value = false
  }

  const uploadLogo = async (brandId: string, size: LOGO_SIZE_SHORT, blob: File[]) => {
    const {
      response,
      error: brandError,
      run: uploadLogo,
    } = useOliveAPI<string>({
      method: HTTP_METHOD.POST,
      url: size === LOGO_SIZE_SHORT.BANNER ? `${oliveBaseUrl}/${brandId}/banner` : `${oliveBaseUrl}/${brandId}/logo_${size}`,
      data: size === LOGO_SIZE_SHORT.BANNER ? { bannerFile: blob[0] } : { logoFile: blob[0] },
      requestConfig: { headers: { 'content-type': 'multipart/form-data' } },
      errorMessage: 'Failed to upload logo',
    })
    isLoading.value = true
    error.value = getOliveError()

    await uploadLogo()

    if (response.value && !brandError.value.hasError) {
      const brand = getBrandById.value(brandId)
      if (brand) {
        switch (size) {
          case LOGO_SIZE_SHORT.BANNER:
            brand.bannerUrl = response.value
            break
          case LOGO_SIZE_SHORT.XS:
            brand.logoUrlXs = response.value
            break
          case LOGO_SIZE_SHORT.SM:
            brand.logoUrlSm = response.value
            break
          case LOGO_SIZE_SHORT.MD:
            brand.logoUrlMd = response.value
            break
        }
        brands.value = useArrayUnique([brand, ...brands.value], (a, b) => a.id === b.id).value
      }
    }
    else { error.value = brandError.value }

    isLoading.value = false
  }

  const downloadLogo = async (url: string) => {
    const {
      response,
      error: brandError,
      run: downloadLogo,
    } = useOliveAPI<Blob>({
      method: HTTP_METHOD.GET,
      url: useOliveURLRequestBuilder(imageProxyUrl, { url } as OliveRequestParams),
      requestConfig: { responseType: 'blob' },
      errorMessage: 'Failed to download image',
    })
    isLoading.value = true
    error.value = getOliveError()

    await downloadLogo()

    if (response.value && !brandError.value.hasError)
      downloadedImage.value = response.value
    else
      error.value = brandError.value

    isLoading.value = false
  }

  const addBrandExternalIdentifier = async (brandId: string, params: IBrandExternalIdentifier) => {
    const {
      response,
      error: brandError,
      run: addBrandExternalIdentifier,
    } = useOliveAPI<IBrandExternalIdentifier>({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${brandId}/external_identifiers`,
      data: params,
      successMessage: 'External Identifier added to brand',
      errorMessage: 'Failed to add External Identifier to brand',
    })
    isLoading.value = true
    error.value = getOliveError()

    await addBrandExternalIdentifier()

    if (response.value && !brandError.value.hasError)
      brandsExternalIdentifiers.value = useArrayUnique([response.value, ...brandsExternalIdentifiers.value], (a, b) => a.id === b.id).value
    else
      error.value = brandError.value

    isLoading.value = false
  }

  const deleteBrandExternalIdentifier = async (brandId: string, externalIdentifierId: string) => {
    const {
      error: brandError,
      run: deleteBrandExternalIdentifier,
    } = useOliveAPI({
      method: HTTP_METHOD.DELETE,
      url: `${oliveBaseUrl}/${brandId}/external_identifiers/${externalIdentifierId}`,
      successMessage: 'External Identifier removed from brand',
      errorMessage: 'Failed to remove External Identifier from Brand',
    })
    isLoading.value = true
    error.value = getOliveError()

    await deleteBrandExternalIdentifier()

    if (!brandError.value.hasError)
      brandsExternalIdentifiers.value = brandsExternalIdentifiers.value.filter(f => f.id !== externalIdentifierId)
    else
      error.value = brandError.value

    isLoading.value = false
  }

  const mergeBrands = async (srcBrandId: string, destBrandId: string) => {
    const {
      error: brandError,
      run: mergeBrands,
    } = useOliveAPI({
      method: HTTP_METHOD.POST,
      url: `${oliveBaseUrl}/${destBrandId}/merge/${srcBrandId}`,
      successMessage: 'Brands have been merged successfully',
      errorMessage: 'Failed to merge Brands',
    })
    isLoading.value = true
    error.value = getOliveError()

    await mergeBrands()

    if (!brandError.value.hasError) {
      brandsExternalIdentifiers.value = brandsExternalIdentifiers.value
        .filter(bei => bei.brandId === srcBrandId)
        .map(bei => ({ ...bei, brandId: destBrandId }))

      useOffersStore().offers = useOffersStore().offers.filter(o => o.brandId === srcBrandId).map(o => ({ ...o, brandId: destBrandId }))

      useStoresStore().stores = useStoresStore().stores.filter(s => s.brandId === srcBrandId).map(s => ({ ...s, brandId: destBrandId }))

      useCardTransactionsStore().transactions = useCardTransactionsStore().transactions.filter(t => t.brandId === srcBrandId).map(t => ({ ...t, brandId: destBrandId }))

      brands.value = brands.value.filter(b => b.id !== srcBrandId)
    }
    else { error.value = brandError.value }

    isLoading.value = false
  }
  // #endregion

  // #region Clear
  const clearCurrentPage = () => {
    brandIdsCurrentPage.value = []
  }

  const clearFilter = () => {
    filter.value = {}
  }

  const clearSelectedBrand = () => {
    selectedBrandId.value = undefined
  }
  // #endregion

  return {
    brands,
    brandsParentCategory,
    brandsFirstCategory,
    brandsSecondCategory,
    brandsExternalIdentifiers,
    numberOfBrands,
    isLoading,
    getBrandById,
    getBrandByIds,
    getBrandsCurrentPage,
    getBrandExternaIdentifiers,
    getSelectedBrand,
    clearSelectedBrand,
    filter,
    error,
    downloadedImage,
    loadBrands,
    loadBrand,
    loadBrandsByIds,
    loadBrandsFirstCategory,
    loadBrandsParentCategory,
    loadBrandsSecondCategory,
    loadBrandExternalIdentifiers,
    addBrand,
    updateBrand,
    uploadLogo,
    addBrandExternalIdentifier,
    deleteBrandExternalIdentifier,
    clearCurrentPage,
    downloadLogo,
    mergeBrands,
    clearFilter,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useBrandsStore as any, import.meta.hot))
