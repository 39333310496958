// eslint-disable-next-line ts/ban-ts-comment
// @ts-nocheck
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueDatePicker from '@vuepic/vue-datepicker'
import { VCodeBlock } from '@wdns/vue-code-block'
import { MdEditor, MdPreview } from 'md-editor-v3'
import { randomPassword } from 'secure-random-password'
import { createVPhoneInput } from 'v-phone-input'
import { createApp } from 'vue'
import VueApexCharts from 'vue3-apexcharts'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import { Cropper } from 'vue-advanced-cropper'
import VueAuth0Plugin from 'vue-auth0-plugin'
import VueGtag from 'vue-gtag'
import VueJsonPretty from 'vue-json-pretty'
import shadow from 'vue-shadow-dom'
import { VAutocomplete } from 'vuetify/components'

import ga from '~/plugins/googleAnalytics'
import App from './App.vue'
import auth0Config from './plugins/auth0'
import pinia from './plugins/pinia'
import { initSentry } from './plugins/sentry'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { router } from './router'
import '~/web-components/creditCard'
import 'vue-json-pretty/lib/styles.css'
import 'v-phone-input/dist/v-phone-input.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-easy-data-table/dist/style.css'
import 'md-editor-v3/lib/style.css'
import '~/styles/global.scss'
import '~/styles/animation.scss'
import 'vue-advanced-cropper/dist/style.css'

await loadFonts()

const app = createApp(App)
initSentry(app, router)
app
  .use(vuetify)
  .use(pinia)
  .use(router)
  .use(VueAuth0Plugin, auth0Config)
  .use(VueApexCharts)
  .use(randomPassword)
  .use(shadow) // https://github.com/2A5F/shadow#readme
  .use(VueGtag, ga, router) // https://matteo-gabriele.gitbook.io/vue-gtag/
  .use(createVPhoneInput()) // https://github.com/paul-thebaud/v-phone-input
  .component(VueCountdown.name as string, VueCountdown) // https://fengyuanchen.github.io/vue-countdown/
  .component('EasyDataTable', Vue3EasyDataTable) // https://hc200ok.github.io/vue3-easy-data-table-doc/
  .component('Datepicker', VueDatePicker) // https://vue3datepicker.com/
  .component('VueJsonPretty', VueJsonPretty) // https://www.npmjs.com/package/vue-json-pretty
  .component('MdEditor', MdEditor) // https://github.com/imzbf/md-editor-v3
  .component('MdPreview', MdPreview) // https://github.com/imzbf/md-editor-v3
  .component('Cropper', Cropper) // https://advanced-cropper.github.io/vue-advanced-cropper
  .component('VCodeBlock', VCodeBlock) // https://webdevnerdstuff.github.io/vue-code-block/
  .component('VAutocomplete', VAutocomplete)
  .mount('#app')
